export default [
  {
    text: "Actions",
    value: "actions",
    sortable: false,
    align: "left",
    width: "60px",
    order: 0,
    hidable: true,
    hidden: false,
  },
  {
    text: "Image",
    value: "imageUrl",
    width: "96px",
    order: 1,
    hidable: false,
    hidden: false,
  },
  {
    text: "Client",
    value: "client",
    width: "64px",
    class: "px-0",
    cellClass: "px-0",
    order: 2,
    hidable: false,
    hidden: false,
  },
  {
    text: "Ver.",
    value: "versionNumber",
    class: "px-0",
    cellClass: "px-0",
    width: "64px",
    order: 3,
    hidable: false,
    hidden: false,
  },
  {
    text: "Name",
    value: "name",
    width: "200px",
    class: "px-0",
    cellClass: "px-0",
    order: 4,
    hidable: false,
    hidden: false,
  },
  {
    text: "Linked Spaces",
    value: "stats",
    sortable: true,
    width: "140px",
    order: 5,
    hidable: true,
    hidden: false,
  },
  {
    text: "Equipment",
    value: "equipmentCount",
    sortable: false,
    width: "120px",
    order: 6,
    hidable: true,
    hidden: false,
  },
  {
    text: "CPLX",
    value: "complexityFactor",
    sortable: true,
    cellClass: "px-2",
    class: "px-2",
    width: "92px",
    order: 7,
    hidable: true,
    hidden: true,
  },
  {
    text: "Created By",
    value: "createdBy",
    width: "100px",
    cellClass: "px-2",
    class: "px-2",
    order: 8,
    hidable: true,
    hidden: false,
  },
  {
    text: "Updated By",
    value: "updatedBy",
    width: "100px",
    cellClass: "px-2",
    class: "px-2",
    order: 9,
    hidable: true,
    hidden: false,
  },
  {
    text: "Created",
    value: "createDate",
    width: "110px",
    order: 10,
    hidable: true,
    hidden: false,
  },
  {
    text: "Last Update",
    value: "updateDate",
    width: "130px",
    order: 11,
    hidable: true,
    hidden: false,
  },
];
